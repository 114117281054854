export type TCreditCardStatus =
  | 'canceled'
  /** The value that indicates this card is valid. */
  | 'chargeable'
  | 'consumed'
  | 'failed'
  | 'pending';

export interface ICreditCardSource {
  brand:
    | 'Visa'
    | 'MasterCard'
    | 'American Express'
    | 'Diners Club'
    | 'Discover'
    | 'JCB'
    | 'UnionPay'
    | 'Unknown';
  exp_month: number;
  exp_year: number;
  id: string;
  last4: string;
  // TODO: Status should not be optional, but needs stripe api to be updated #2795
  status?: TCreditCardStatus;
}

export type TBankAccountStatus =
  | 'new'
  | 'validated'
  /** The value that indicates this bank account is valid. */
  | 'verified'
  | 'verification_failed'
  | 'errored';

export interface IBankAccountSource {
  account_holder_name: string | null;
  bank_name: string | null;
  id: string;
  last4: string;
  status: TBankAccountStatus;
}

export type TSource = ICreditCardSource | IBankAccountSource;

export function isCreditCardSource(source: TSource): source is ICreditCardSource {
  if ((source as ICreditCardSource).exp_month) {
    return true;
  }

  return false;
}

export function isBankAccountSource(source: TSource): source is IBankAccountSource {
  if ((source as IBankAccountSource).bank_name) {
    return true;
  }

  return false;
}

export const sourceValidation = {
  routing_number: {
    presence: {
      allowEmpty: false,
    },
  },
  account_number: {
    presence: {
      allowEmpty: false,
    },
  },
  account_holder_name: {
    presence: {
      allowEmpty: false,
    },
  },
};
