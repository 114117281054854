import type { WithT } from 'i18next';
import type { IObservableArray } from 'mobx';
import type { Dispatch } from 'react';

import type {
  Account,
  CustomField,
  Font,
  IMergeField,
  TemplateClass as TemplateClassType,
} from '@feathr/blackbox';
import { FieldCollection, TemplateClass } from '@feathr/blackbox';
import type { ListResponse } from '@feathr/rachis';

import type {
  Bee,
  BeePluginContentDialogHandler,
  BeePluginError,
  FontElement,
  IBeeConfig,
  IMergeContent,
  IMergeTag,
  IPluginForm,
  ISpecialLink,
} from '.';

export enum EBeeEditorAction {
  save = 'save',
  send = 'send',
  preview = 'preview',
  resolve = 'resolve',
  addDynamicContent = 'adding',
  manageForm = 'manageForm',
  error = 'error',
}

/*
 * Delineating form field types that are not explicitly
 * provided through the BeePlugin types
 */
interface IBeePluginFormFieldOption {
  value: string;
  type?: 'option' | 'optgroup';
  label?: string;
  options?: IBeePluginFormFieldOption[];
}

export interface IBeePluginFormField {
  type:
    | 'text'
    | 'textarea'
    | 'email'
    | 'url'
    | 'checkbox'
    | 'submit'
    | 'color'
    | 'datalist'
    | 'date'
    | 'file'
    | 'hidden'
    | 'number'
    | 'radio'
    | 'range'
    | 'select'
    | 'tel';
  label: string;
  canBeRemovedFromLayout: boolean;
  removeFromLayout?: true;
  attributes: {
    accesskey?: string;
    autocomplete?: string;
    class?: string;
    checked?: boolean;
    contenteditable?: boolean;
    'data-callback'?: string;
    dir?: string;
    disabled?: boolean;
    readonly?: boolean;
    required?: boolean;
    draggable?: boolean;
    hidden?: boolean;
    id?: string;
    name?: string;
    multiple?: string;
    placeholder?: string;
    itemid?: string;
    itemprop?: string;
    itemref?: string;
    itemscope?: string;
    itemtype?: string;
    lang?: string;
    size?: string;
    tabindex?: string;
    title?: string;
    value?: string;
  };
  options?: IBeePluginFormFieldOption[];
}

export const EXTRA_FONTS: FontElement[] = [
  {
    name: 'Libre Baskerville',
    fontFamily: "'Libre Baskerville', serif",
    url: 'https://fonts.googleapis.com/css?family=Libre+Baskerville',
  },
  {
    name: 'Muli',
    fontFamily: 'Muli, Arial, Helvetica, sans-serif',
    url: 'https://fonts.googleapis.com/css?family=Muli',
  },
  {
    name: 'Raleway',
    fontFamily: 'Raleway, Arial, Helvetica, sans-serif',
    url: 'https://fonts.googleapis.com/css?family=Raleway',
  },
  {
    name: 'Questrial',
    fontFamily: 'Questrial, Arial, Helvetica, sans-serif',
    url: 'https://fonts.googleapis.com/css?family=Questrial',
  },
  {
    name: 'Poppins',
    fontFamily: 'Poppins, Arial, Helvetica, sans-serif',
    url: 'https://fonts.googleapis.com/css?family=Poppins',
  },
];

export const formCustomFieldsFilters: Record<string, string[]> = {
  collection__in: [FieldCollection.Person, FieldCollection.Breadcrumb],
};

export function getMergefieldCustomFieldsFilters(
  cls: TemplateClassType,
): Record<string, string | string[]> {
  if (cls === TemplateClass.PinpointEmail) {
    return {
      collection__in: [FieldCollection.Person, FieldCollection.Partner],
    };
  }

  if (cls !== TemplateClass.LandingPage) {
    return { collection: FieldCollection.Partner };
  }

  return {};
}

export function getDefaultForm(customFields: IObservableArray<CustomField>): IPluginForm {
  const sanitize = (key: string): string => key.replace(/[^a-zA-Z0-9_-]/g, '');
  const fields = {
    Name: {
      type: 'text',
      label: 'Name',
      canBeRemovedFromLayout: true,
      attributes: {
        required: false,
        placeholder: 'Joe Birden',
        name: 'person-name',
        class: 'feathr-form-field',
        id: 'fff-Name',
      },
    },
    First: {
      type: 'text',
      label: 'First Name',
      canBeRemovedFromLayout: true,
      attributes: {
        required: false,
        placeholder: 'Joe',
        name: 'person-first-name',
        class: 'feathr-form-field',
        id: 'fff-First-Name',
      },
    },
    Last: {
      type: 'text',
      label: 'Last Name',
      canBeRemovedFromLayout: true,
      attributes: {
        required: false,
        placeholder: 'Birden',
        name: 'person-last-name',
        class: 'feathr-form-field',
        id: 'fff-Last-Name',
      },
    },
    Email: {
      type: 'email',
      label: 'Primary email',
      canBeRemovedFromLayout: true,
      attributes: {
        required: true,
        placeholder: 'joe@birden.org',
        name: 'person-email',
        class: 'feathr-form-field',
        id: 'fff-Email',
      },
    },
    Phone: {
      type: 'tel',
      label: 'Phone',
      canBeRemovedFromLayout: true,
      attributes: {
        required: false,
        placeholder: '352-000-0000',
        name: 'person-phone',
        class: 'feathr-form-field',
        id: 'fff-Phone',
      },
    },
    Occupation: {
      type: 'text',
      label: 'Occupation',
      canBeRemovedFromLayout: true,
      attributes: {
        required: false,
        placeholder: '',
        name: 'person-occupation',
        class: `feathr-form-field`,
        id: 'fff-Occupation',
      },
    },
    Company: {
      type: 'text',
      label: 'Company',
      canBeRemovedFromLayout: true,
      attributes: {
        required: false,
        placeholder: '',
        name: 'person-companies',
        class: `feathr-form-field`,
        id: 'fff-Company',
      },
    },
    Message: {
      type: 'textarea',
      label: 'Message',
      canBeRemovedFromLayout: true,
      attributes: {
        required: false,
        placeholder: 'Howdy!',
        name: 'breadcrumb-msg',
        class: 'feathr-form-field',
        id: 'fff-Message',
      },
    },
    Privacy: {
      type: 'checkbox',
      label:
        'Please review and accept our <a href="https://example.com/" target="_blank">privacy policy</a>.',
      canBeRemovedFromLayout: true,
      attributes: {
        required: true,
        name: 'privacy',
        id: 'fff-Privacy',
      },
    },
    Submit: {
      type: 'submit',
      label: '',
      canBeRemovedFromLayout: false,
      attributes: {
        value: 'Submit',
        name: 'submit_button',
        class: 'feathr-form-submit',
        id: 'fff-Submit',
        'data-callback': 'Thanks!',
      },
    },
    ...customFields
      .filter(
        (field) =>
          ![
            'Name',
            'First Name',
            'Last Name',
            'Email',
            'Phone',
            'Occupation',
            'Company',
            'Message',
            'Privacy',
            'Submit',
          ].includes(sanitize(field.get('u_key'))),
      )
      .reduce((acc, field) => {
        const key = sanitize(field.get('u_key'));
        acc[key] = {
          type: 'text',
          label: field.get('u_key'),
          removeFromLayout: true,
          canBeRemovedFromLayout: true,
          attributes: {
            required: false,
            name: `${field.get('collection').toLowerCase()}-${field.get('f_key')}`,
            class: 'feathr-form-field',
            id: `fff-${key}`,
          },
        };
        return acc;
      }, {}),
  };

  return {
    structure: {
      fields,
      layout: Object.keys(fields).map((key) => [key]),
      title: '',
      description: '',
      attributes: {
        action: '',
        method: '',
      },
    },
  };
}

export interface IBeeEditorActions {
  dispatchEditorAction: Dispatch<IBeeEditorAction>;
  handleManageForm: BeePluginContentDialogHandler<IPluginForm, undefined, IPluginForm['structure']>;
  onAutoSave?: (json: string) => void;
  onChange: (json: string) => void;
  onError: (error: BeePluginError) => void;
  onSave: (json: string) => void;
  onSend: () => void;
  setBeePlugin: (newPlugin: Bee) => void;
}

export interface IBeeEditorModels {
  account: Account;
  fonts: ListResponse<Font>;
  formCustomFields: ListResponse<CustomField>;
  mergefieldCustomFields: ListResponse<CustomField>;
}

export type TDynamicContentResolveHandler = (
  data: ISpecialLink | IMergeTag,
  options?: Record<string, unknown>,
) => void;
export type TFormResolveHandler = (data: IPluginForm, options?: Record<string, unknown>) => void;
export type TResolveHandler = TFormResolveHandler | TDynamicContentResolveHandler;

export interface IBeeEditorAction {
  type: EBeeEditorAction;
  json?: string;
  error?: BeePluginError;
  form?: IPluginForm['structure'];
  resolve?: TResolveHandler;
  reject?: (error?: Error) => void;
}

export interface IBeeEditorConfig extends WithT {
  actions: IBeeEditorActions;
  isReadOnly?: boolean;
  models: IBeeEditorModels;
  mergefields: IMergeField[];
  shouldParseMergeTags: boolean;
}

export function getDefaultTemplateEditorConfig({
  t,
  actions,
  isReadOnly = false,
  mergefields,
  models,
  shouldParseMergeTags = false,
}: IBeeEditorConfig): IBeeConfig {
  const specialLinks: ISpecialLink[] = mergefields
    .filter((field) => field.type === 'link')
    .map((field) => ({ type: 'Link', label: field.name, link: field.value }));

  const mergeTags: IMergeTag[] = (
    shouldParseMergeTags
      ? mergefields.filter((field) => !['first_name', 'last_name'].includes(field.defaultPath!))
      : mergefields
  )
    .filter((field) => field.type === 'tag')
    // BeeEditor doesn't allow HTML in mergeTag name
    .map((field) => ({
      name: field.isCustom
        ? t('{{fieldName}} (custom data)', { fieldName: field.name })
        : field.name,
      value: field.value,
    }));

  const mergeContents: IMergeContent[] = mergefields
    .filter((field) => field.type === 'content')
    .map((field) => ({ name: field.name, value: field.value }));

  const { account, fonts, formCustomFields } = models;

  const readOnly = (function (): Record<string, Record<string, Record<string, boolean>>> {
    /*
     * In order to make the editor read only, we have to manually disable each possible element
     * that can appear in the editor.
     */
    const behaviors = {
      canSelect: false,
      canAdd: false,
      canViewSidebar: false,
      canClone: false,
      canMove: false,
      canDelete: false,
    };
    const contentKeys = [
      'title',
      'viwed',
      'spacer',
      'text',
      'button',
      'image',
      'divider',
      'social',
      'dynamic',
      'html',
      'video',
      'form',
      'icons',
      'paragraph',
      'list',
      'menu',
    ];

    const content = contentKeys.reduce((obj, key) => {
      obj[key] = { behaviors };
      return obj;
    }, {});

    return {
      tabs: {
        content: { show: false },
        rows: { show: false },
        settings: { show: false },
      },
      rows: {
        behaviors,
      },
      content,
    };
  })();

  return {
    advancedPermissions: isReadOnly ? readOnly : undefined,
    // Autosave value should be in seconds
    autosave: actions.onAutoSave ? 60 : undefined,
    defaultForm: getDefaultForm(formCustomFields.models),
    specialLinks,
    mergeTags,
    mergeContents,
    trackChanges: true,
    onChange: actions.onChange,
    loadingSpinnerTheme: 'light',
    sidebarPosition: 'left',
    uid: account.id,
    container: 'bee-plugin-container',
    onAutoSave: actions.onAutoSave,
    onError: actions.onError,
    onSave: actions.onSave,
    onSend: actions.onSend,
    editorFonts: {
      showDefaultFonts: true,
      customFonts: [
        ...EXTRA_FONTS,
        ...fonts.models.map((font) => ({
          name: font.name,
          fontFamily: `'${font.name}', Arial, Helvetica, sans-serif`,
          url: `${BLACKBOX_URL}fonts/${font.id}/css`,
        })),
      ],
    },
    contentDialog: {
      /*
       * this key doesn't exist on the interface provided by Bee,
       * but it's necessary to show the Dynamic Content content dialog
       */
      // @ts-ignore: see above
      mergeContents: {
        label: t('Add/Edit Dynamic Content'),
        handler: async (resolve, reject): Promise<void> => {
          await actions.dispatchEditorAction({
            type: EBeeEditorAction.addDynamicContent,
            resolve,
            reject,
          });
        },
      },
      manageForm: {
        label: t('Advanced Settings'),
        // @ts-ignore: have to support legacy template form structure
        handler: actions.handleManageForm,
      },
    },
  };
}
