import type { TFunction } from 'i18next';

import {
  EBlackbaudRaisersEdgeActivityMappingKeys,
  EBlackbaudRaisersEdgeContactMappingKeys,
} from '@feathr/blackbox';

export const BlackbaudRaisersEdgeContactFieldsTranslationMap = (
  t: TFunction,
  field: string,
): string => {
  const map = {
    [EBlackbaudRaisersEdgeContactMappingKeys.address]: t('Address'),
    [EBlackbaudRaisersEdgeContactMappingKeys.age]: t('Age'),
    [EBlackbaudRaisersEdgeContactMappingKeys.birthdate]: t('Birth date'),
    [EBlackbaudRaisersEdgeContactMappingKeys.constituent_codes]: t('Constituent codes'),
    [EBlackbaudRaisersEdgeContactMappingKeys.consecutive_years_given]: t('Consecutive years given'),
    [EBlackbaudRaisersEdgeContactMappingKeys.date_added]: t('Date added'),
    [EBlackbaudRaisersEdgeContactMappingKeys.date_modified]: t('Date modified'),
    [EBlackbaudRaisersEdgeContactMappingKeys.date_of_first_gift]: t('Date of first gift'),
    [EBlackbaudRaisersEdgeContactMappingKeys.date_of_last_gift]: t('Date of last gift'),
    [EBlackbaudRaisersEdgeContactMappingKeys.deceased]: t('Deceased'),
    [EBlackbaudRaisersEdgeContactMappingKeys.deceased_date]: t('Deceased date'),
    [EBlackbaudRaisersEdgeContactMappingKeys.email]: t('Primary email'),
    [EBlackbaudRaisersEdgeContactMappingKeys.emails]: t('Additional emails'),
    [EBlackbaudRaisersEdgeContactMappingKeys.first]: t('First name'),
    [EBlackbaudRaisersEdgeContactMappingKeys.first_gift_amount]: t('First gift amount'),
    [EBlackbaudRaisersEdgeContactMappingKeys.former_name]: t('Former name'),
    [EBlackbaudRaisersEdgeContactMappingKeys.fundraiser_status]: t('Fundraiser status'),
    [EBlackbaudRaisersEdgeContactMappingKeys.gender]: t('Gender'),
    [EBlackbaudRaisersEdgeContactMappingKeys.gives_anonymously]: t('Gives anonymously'),
    [EBlackbaudRaisersEdgeContactMappingKeys.greatest_gift_amount]: t('Greatest gift amount'),
    [EBlackbaudRaisersEdgeContactMappingKeys.id]: t('System record ID'),
    [EBlackbaudRaisersEdgeContactMappingKeys.inactive]: t('Inactive'),
    [EBlackbaudRaisersEdgeContactMappingKeys.last]: t('Last name'),
    [EBlackbaudRaisersEdgeContactMappingKeys.last_gift_amount]: t('Last gift amount'),
    [EBlackbaudRaisersEdgeContactMappingKeys.lookup_id]: t('Lookup ID'),
    [EBlackbaudRaisersEdgeContactMappingKeys.marital_status]: t('Marital status'),
    [EBlackbaudRaisersEdgeContactMappingKeys.middle]: t('Middle name'),
    [EBlackbaudRaisersEdgeContactMappingKeys.name]: t('Full name'),
    [EBlackbaudRaisersEdgeContactMappingKeys.phone]: t('Phone'),
    [EBlackbaudRaisersEdgeContactMappingKeys.primary_addressee]: t('Primary addressee'),
    [EBlackbaudRaisersEdgeContactMappingKeys.primary_salutation]: t('Primary salutation'),
    [EBlackbaudRaisersEdgeContactMappingKeys.preferred_name]: t('Preferred name'),
    [EBlackbaudRaisersEdgeContactMappingKeys.solicit_codes]: t('Solicit codes'),
    [EBlackbaudRaisersEdgeContactMappingKeys.suffix]: t('Suffix'),
    [EBlackbaudRaisersEdgeContactMappingKeys.suffix_2]: t('Suffix (secondary)'),
    [EBlackbaudRaisersEdgeContactMappingKeys.title]: t('Title'),
    [EBlackbaudRaisersEdgeContactMappingKeys.title_2]: t('Title (secondary)'),
    [EBlackbaudRaisersEdgeContactMappingKeys.total_giving]: t('Total giving'),
    [EBlackbaudRaisersEdgeContactMappingKeys.total_years_given]: t('Total years giving'),
    [EBlackbaudRaisersEdgeContactMappingKeys.type]: t('Type'),
  };
  return map[field];
};

export const BlackbaudRaisersEdgeActivityMappingFieldTranslationMap = (
  t: TFunction,
  field: string,
): string => {
  const map = {
    [EBlackbaudRaisersEdgeActivityMappingKeys.gift_events]: t('Gift added'),
  };
  return map[field];
};

export const BlackbaudRaisersEdgeActivityMappingToFeathrFieldTranslationMap = (
  t: TFunction,
  field: string,
): string => {
  const map = {
    [EBlackbaudRaisersEdgeActivityMappingKeys.gift_events]: t("Raiser's Edge NXT gift added"),
  };
  return map[field];
};
