import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import {
  concatPath,
  localTime,
  moment,
  shortCodeToken,
  TimeFormat,
  toQueryString,
} from '@feathr/hooks';
import type {
  Attributes,
  ICollectionStore,
  IMetadata,
  IRachisMessage,
  IWretchResponseValid,
  ListResponse,
  TConstraints,
} from '@feathr/rachis';
import { BulkCollection, isWretchError, wretch } from '@feathr/rachis';

import { CAMPAIGN_MAX_BUDGET, CAMPAIGN_MIN_BUDGET } from '../accounts';
import type { IAddress } from '../address';
import { addressConstraints } from '../address';
import type { EmailVerification, EmailVerifications } from '../email_verifications';
import { REGEX_INPUT_VALIDATION } from '../global';
import { ECollectionClassName } from '../model';
import { ReportModel } from '../model/report';
import type { TAttributionModel } from '../stats';
import type { ITemplate, Template } from '../templates';
import { TemplateClass } from '../templates';
import type {
  IAutoPinpointEmailCampaign,
  ICampaignAttributes,
  IDripCampaign,
  IEmailBaseCampaign,
  IGoogleAdsLocationSuggestion,
  IGoogleAdsSmartCampaign,
  IGoogleAdsSuggestProps,
  IPinpointEmailBaseCampaign,
  IPinpointEmailCampaign,
  IPinpointPartnerMessage,
  ISegmentCampaignAttributes,
  ISmartPinpointEmailBaseCampaign,
  ISmartPinpointEmailCampaign,
  TAffinityCampaign,
  TCampaignGroup,
  TConversationCampaign,
  TEmailListCampaign,
  TEmailListFacebookCampaign,
  TFacebookCampaign,
  TGoogleAdsSuggestions,
  TLandingPageCampaign,
  TLookalikeCampaign,
  TMobileGeoFenceRetargetingCampaign,
  TMobileGeoFencingCampaign,
  TReferralCampaign,
  TSearchCampaign,
  TSeedSegmentCampaign,
  TTrackedLinkCampaign,
} from './types';
import { CampaignClass, CampaignState, FacebookObjectiveType } from './types';

import defaultPageTemplate from './defaultPageTemplate.json';

const {
  Segment,
  Lookalike,
  SeedSegment,
  Affinity,
  Search,
  EmailList,
  MobileGeoFencing,
  MobileGeoFenceRetargeting,
  Referral,
  Conversation,
  LandingPage,
  Facebook,
  EmailListFacebook,
  TrackedLink,
  DripCampaign: Drip,
  PinpointEmail,
  SmartPinpointEmail,
  AutoPinpointEmail,
  GoogleAdsSmart,
  PinpointPartnerMessage: PartnerMessage,
} = CampaignClass;

export const CampaignLabelMap = new Map<CampaignClass, string>([
  [Segment, 'Retargeting'],
  [Lookalike, 'Lookalike'],
  [SeedSegment, 'Lookalike'],
  [Affinity, 'Affinity'],
  [Search, 'Search Keyword'],
  [EmailList, 'Email Mapping'],
  [MobileGeoFencing, 'Mobile Geofencing'],
  [MobileGeoFenceRetargeting, 'Historical Geofencing'],
  [Referral, 'Invites'],
  [Conversation, 'Conversation'],
  [LandingPage, 'Landing Page'],
  [Facebook, 'Meta Retargeting'],
  [EmailListFacebook, 'Meta Email Mapping'],
  [TrackedLink, 'Tracked Link'],
  [Drip, 'Drip'],
  [PinpointEmail, 'Single Send'],
  [SmartPinpointEmail, 'Smart Send'],
  [AutoPinpointEmail, 'Auto Send'],
  [GoogleAdsSmart, 'Google Ad Grants'],
]);

export const CampaignGroupMap: Record<TCampaignGroup, CampaignClass[]> = {
  ads: [
    Affinity,
    EmailList,
    EmailListFacebook,
    Facebook,
    GoogleAdsSmart,
    Lookalike,
    MobileGeoFenceRetargeting,
    MobileGeoFencing,
    Search,
    SeedSegment,
    Segment,
  ],
  all: [
    Affinity,
    EmailList,
    EmailListFacebook,
    Facebook,
    GoogleAdsSmart,
    Lookalike,
    MobileGeoFenceRetargeting,
    MobileGeoFencing,
    Search,
    SeedSegment,
    Segment,
    AutoPinpointEmail,
    PinpointEmail,
    SmartPinpointEmail,
    Drip,
    Referral,
    Conversation,
    TrackedLink,
    LandingPage,
  ],
  email: [AutoPinpointEmail, PinpointEmail, SmartPinpointEmail, Drip],
  'google-ads': [GoogleAdsSmart],
  monetization: [
    PinpointEmail,
    Segment,
    Search,
    EmailList,
    Affinity,
    Lookalike,
    MobileGeoFencing,
    MobileGeoFenceRetargeting,
  ],
  other: [Referral, Conversation, TrackedLink, LandingPage],
};

export const FacebookCampaignObjectiveMap = new Map<FacebookObjectiveType, string>([
  [FacebookObjectiveType.Reach_Legacy, 'Reach'],
  [FacebookObjectiveType.Traffic_Legacy, 'Traffic'],
  [FacebookObjectiveType.Reach, 'Reach'],
  [FacebookObjectiveType.Traffic, 'Traffic'],
]);

export function getMaxTargetValue(maxBudget = CAMPAIGN_MAX_BUDGET, isMonetization = false): number {
  if (!isMonetization) {
    return maxBudget;
  }

  /*
   * Budgets must be less than the max budget or the equivalent number of impressions
   * at a $5CPM for a Monetization campaign.The equivalent number of
   * impressions is $5 / 1000 imps.
   * $10,000 * 1000 imp / $5 = 2,000,000 impressions.
   */
  return (maxBudget * 1000) / 5;
}

export const leadTimeCampaigns = [
  Search,
  EmailList,
  MobileGeoFencing,
  MobileGeoFenceRetargeting,
  SeedSegment,
];

export function getMinDaysAdvance(type: CampaignClass): number {
  if (leadTimeCampaigns.includes(type)) {
    return 5;
  } else if ([Referral, LandingPage].includes(type)) {
    return 0;
  }
  return 1;
}

export function getMinStartDate(type: CampaignClass): moment.Moment {
  const minDaysAdvance = getMinDaysAdvance(type);
  return moment.utc().add(minDaysAdvance, 'days').endOf('day');
}

export function getMinDuration(): moment.Duration {
  return moment.duration(3, 'days');
}

const baseConstraints: TConstraints<ICampaignAttributes> = {
  date_start: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    datetime: (...args: any[]) => {
      const attributes = args[1];
      if (attributes._cls === LandingPage) {
        return undefined;
      }
      if (attributes.state === CampaignState.Draft) {
        const minDaysAdvance = getMinDaysAdvance(attributes._cls);
        return {
          earliest: moment.utc().add(minDaysAdvance, 'days').format(TimeFormat.isoDate),
          message: `^Campaigns of this type must be made at least ${minDaysAdvance} day${
            minDaysAdvance > 1 ? 's' : ''
          } in advance.`,
        };
      }
      return true;
    },
    presence: (...args: any[]) => {
      const attributes = args[1];
      if (attributes._cls === LandingPage) {
        return undefined;
      }
      return {
        allowEmpty: false,
        message: '^Start date cannot be empty.',
      };
    },
  },
  date_end: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    datetime: (value: string, attributes: any) => {
      if (attributes._cls && attributes._cls === LandingPage) {
        return undefined;
      }
      if (attributes.date_start) {
        const dateStart = moment.utc(attributes.date_start, TimeFormat.isoDateTime);
        const dateEnd = moment.utc(value, TimeFormat.isoDateTime);
        const minDuration = getMinDuration();

        if (dateEnd.isBefore(dateStart)) {
          return {
            earliest: dateStart.add(minDuration).format(TimeFormat.isoDateTime),
            message: '^End date must be after start date.',
          };
        } else {
          return {
            earliest: dateStart.add(minDuration).format(TimeFormat.isoDateTime),
            message: `Campaigns of this type must be at least ${minDuration.asDays()} days long.`,
          };
        }
      }

      return true;
    },
    presence: (...args: any[]) => {
      const attributes = args[1];
      if (attributes && attributes._cls && attributes._cls === LandingPage) {
        return undefined;
      }
      return {
        allowEmpty: false,
        message: '^End date cannot be empty.',
      };
    },
  },
  state: {
    inclusion: {
      within: [
        CampaignState.Archived,
        CampaignState.Draft,
        CampaignState.Erroring,
        CampaignState.Published,
        CampaignState.Publishing,
        CampaignState.Stopped,
      ],
      message: '^The campaign is not in a valid state.',
    },
  },
  name: {
    exclusion: {
      within: ['New Draft Campaign'],
      message: '^Change the default name for this campaign.',
    },
    presence: {
      allowEmpty: false,
    },
    format: {
      pattern: REGEX_INPUT_VALIDATION,
      flags: 'i',
      message: '^Campaign names must consist of letters, numbers, and basic symbols.',
    },
  },
  destination_url: (...args: any[]) => {
    const attributes = args[1];
    if ([LandingPage, TrackedLink].includes(attributes._cls)) {
      return undefined;
    }
    return {
      url: true,
      presence: {
        allowEmpty: false,
      },
    };
  },
};

export abstract class Campaign<
  IAttributes extends ICampaignAttributes = ICampaignAttributes,
> extends ReportModel<IAttributes> {
  public reportKey = 'c' as const;
  public className = 'campaign' as const;
  public collection: Campaigns<this> | null = null;

  public constraints: TConstraints<IAttributes> = {
    ...(baseConstraints as TConstraints<IAttributes>),
  };

  constructor(attributes: Partial<IAttributes> = {}) {
    super(attributes);

    makeObservable(this);
  }

  public set(patch: Partial<Attributes<this>>, dirty = true): void {
    super.set(patch, dirty);
  }

  public getItemUrl(pathSuffix?: string): string {
    const localPathSuffix = pathSuffix ?? (this.get('_cls') === Conversation ? 'edit' : undefined);

    const flightId = this.get('flight');
    if (flightId) {
      return concatPath(
        `/projects/${this.get('event')}/flights/${flightId}/campaigns/${this.id}`,
        localPathSuffix,
      );
    }
    return concatPath(`/projects/${this.get('event')}/campaigns/${this.id}`, localPathSuffix);
  }

  public getDefaults(): Partial<IAttributes> {
    return {
      ...super.getDefaults(),
      date_created: moment.utc(),
      date_start: moment.utc(),
      date_last_modified: moment.utc(),
      exposure_settings: {
        target_value: 0,
      },
      exposure_model: {
        kind: 'flat',
        freq_cap: 9,
        freq_cap_users: 0,
        freq_period: 480,
      },
      is_enabled: true,
      name: '',
      rerun: false,
      state: 'draft',
      total_stats: { flavors: {}, spend: 0 },
    } as Partial<IAttributes>;
  }

  @action.bound
  public async publish(validate = true): Promise<Campaign> {
    if (!this.collection) {
      throw new Error('Campaign is not in a collection.');
    }
    if (validate && !this.isValid(['_cls', ...Object.keys(this.constraints)], false)) {
      throw new Error('Campaign is invalid');
    }
    return this.collection.publish(this);
  }

  public async stop(): Promise<Campaign> {
    const patch = {
      state: this.get('_cls') === PartnerMessage ? CampaignState.Draft : CampaignState.Stopped,
    } as Partial<Attributes<this>>;
    this.set(patch);
    return this.patch(patch);
  }

  public get reportRangeStart(): string {
    /*
     * date_send_start has a timestamp for the exact time emails should go out,
     * whereas date_start is generally only used as a representation for the date itself,
     * removed from the time
     */
    const dateStart = this.isEmail ? this.get('date_send_start') : this.get('date_start');
    const dateCreated = this.get('date_created');
    if (dateStart) {
      return dateStart;
    }
    if (dateCreated) {
      return dateCreated;
    }
    return moment.utc().format(TimeFormat.isoDate);
  }

  @computed
  public get localStartTime(): string {
    return localTime(this.get('date_start'));
  }

  @computed
  public get localEndTime(): string {
    return localTime(this.get('date_end'));
  }

  /**
   * Type guard for Pinpoint campaigns
   */
  public isPinpointCampaign(): this is PinpointEmailBaseCampaign {
    return [PinpointEmail, SmartPinpointEmail, AutoPinpointEmail, PartnerMessage].includes(
      this.get('_cls'),
    );
  }

  @computed
  public get isCreativesCampaign(): boolean {
    return [
      Affinity,
      EmailList,
      EmailListFacebook,
      Facebook,
      Lookalike,
      MobileGeoFenceRetargeting,
      MobileGeoFencing,
      Search,
      SeedSegment,
      Segment,
    ].includes(this.get('_cls'));
  }

  @computed
  public get isAwarenessCampaign(): boolean {
    return [Affinity, Search, Lookalike, MobileGeoFenceRetargeting, MobileGeoFencing].includes(
      this.get('_cls'),
    );
  }

  /**
   * Getter that returns whether or not the campaign is an ad campaign.
   * Uses campaign.isCreativesCampaign, but is written in this way
   * for clarity in other parts of the app.
   */
  @computed
  public get isAdCampaign(): boolean {
    return this.isCreativesCampaign;
  }

  @computed
  public get isSegmentCampaign(): boolean {
    return [Segment, Facebook].includes(this.get('_cls'));
  }

  @computed
  public get isLandingPageCampaign(): boolean {
    return [LandingPage].includes(this.get('_cls'));
  }

  @computed
  public get reportRangeEnd(): string {
    const dateEnd = this.isEmail ? this.get('date_send_end') : this.get('date_end');
    const momentEnd = moment.utc(dateEnd);
    const stats = this.get('total_stats');
    const isConverting = stats.conversions && (stats.conversions.full?.num || 0);
    if (dateEnd && isConverting) {
      return momentEnd.add(30, 'days').format(TimeFormat.isoDate);
    }
    if (dateEnd) {
      return dateEnd;
    }
    return moment.utc().format(TimeFormat.isoDate);
  }

  @computed
  public get totalViews(): number {
    const {
      flavors: { ad_view: adViews = 0, page_view: pageViews = 0, email_view: emailViews = 0 },
    } = this.get('total_stats') || {};
    return adViews + pageViews + emailViews;
  }

  @computed
  public get totalClicks(): number {
    const {
      flavors: {
        ad_click: adClick = 0,
        page_link_click: pageClick = 0,
        email_link_click: emailClick = 0,
      },
    } = this.get('total_stats') || {};
    return adClick + pageClick + emailClick;
  }

  @computed
  public get name(): string {
    const label = CampaignLabelMap.get(this.get('_cls'));
    return (
      this.get('name', '' as Exclude<IAttributes['name'], undefined>).trim() ||
      `Unnamed ${label ? label + ' ' : ''}Campaign`
    );
  }

  @computed
  public get isMonetization(): boolean {
    return (
      this.get('parent_kind') === 'partner' &&
      this.get('exposure_settings').target_type === 'fixed_impressions'
    );
  }

  @computed
  public get isFacebook(): boolean {
    return [Facebook, EmailListFacebook].includes(this.get('_cls'));
  }

  @computed
  public get isGoogle(): boolean {
    return this.get('_cls') === GoogleAdsSmart;
  }

  @computed
  public get isSingleSend(): boolean {
    return this.get('_cls') === PinpointEmail;
  }

  @computed
  public get isEmail(): boolean {
    return [PinpointEmail, SmartPinpointEmail, AutoPinpointEmail, Drip].includes(this.get('_cls'));
  }

  /** Type guard for Email  */
  public isEmailType(): this is PinpointEmailCampaign {
    return this.isEmail;
  }

  /**
   * Getter that checks if a campaign was created after the date
   * scripted updates was merged in. Campaigns that were live before
   * and after the merge may have statistical discrepancies across
   * report components due to changes in calculation methodology.
   */
  @computed
  public get isCreatedAfterScriptedUpdates(): boolean {
    return moment(this.get('date_start')).isAfter(moment('02-21-2023', 'MM-DD-YYYY'));
  }

  public isAfterDateSendStart({
    now = moment.utc(),
    offset = 0,
    offset_unit = 'seconds',
  }: {
    now?: moment.Moment;
    offset?: number;
    offset_unit?: moment.unitOfTime.DurationConstructor;
  } = {}): boolean {
    const sendStart = moment.utc(this.get('date_send_start'), moment.ISO_8601);
    return now.isSameOrAfter(sendStart.add(offset, offset_unit));
  }

  /**
   * Getter that checks if an email campaign is past its end date
   * We offset 1 hour by default to allow time for the num_targeted stat
   * to finish processing before we display it.
   */
  public get isAfterDateSendEnd(): boolean {
    const now = moment.utc();
    const sendEnd = this.get('date_send_end');
    if (!sendEnd) {
      return false;
    }
    const sendEndMoment = moment.utc(sendEnd, moment.ISO_8601);
    return now.isSameOrAfter(sendEndMoment.add(1, 'hour'));
  }

  /**
   * Getter that checks if a non-email campaign is past its end date.
   */
  public get isAfterDateEnd(): boolean {
    const now = moment.utc();
    const end = this.get('date_end');
    if (!end) {
      return false;
    }
    return now.isSameOrAfter(end);
  }

  /**
   * Getter that checks if a non-email campaign is past its start date
   */
  public get isAfterDateStart(): boolean {
    const now = moment.utc();
    const start = this.get('date_start');
    if (!start) {
      return false;
    }
    return now.isSameOrAfter(start);
  }

  /**
   * Getter that checks if any campaign type is past its end date.
   */
  public get isPastEndDate(): boolean {
    return this.isEmail ? this.isAfterDateSendEnd : this.isAfterDateEnd;
  }

  public get isPastStartDate(): boolean {
    return this.isEmail ? this.isAfterDateSendStart() : this.isAfterDateStart;
  }
}

const monetizationConstraints = {
  monetization_value: {
    presence: {
      message: '^Sponsor package value cannot be empty',
      allowEmpty: false,
    },
    numericality: {
      greaterThan: 0,
      message: '^Sponsor package value should be greater than $0',
    },
  },
};

export abstract class CreativesCampaign<
  T extends ICampaignAttributes = ICampaignAttributes,
> extends Campaign<T> {
  public constraints: TConstraints<TCampaignAttributes> = {
    ...baseConstraints,
    monetization_value: () => {
      if (this.isMonetization) {
        return monetizationConstraints.monetization_value;
      }
      return null;
    },
  };
}

export class BaseCampaign extends Campaign<ICampaignAttributes> {}

export class SegmentCampaign extends CreativesCampaign<ISegmentCampaignAttributes> {}

export class LookalikeCampaign extends CreativesCampaign<TLookalikeCampaign> {}

export class SeedSegmentCampaign extends CreativesCampaign<TSeedSegmentCampaign> {}

export class AffinityCampaign extends CreativesCampaign<TAffinityCampaign> {}

export class SearchCampaign extends CreativesCampaign<TSearchCampaign> {}

export class EmailListCampaign extends CreativesCampaign<TEmailListCampaign> {}

export class MobileGeoFencingCampaign extends CreativesCampaign<TMobileGeoFencingCampaign> {}

export class MobileGeoFenceRetargetingCampaign extends CreativesCampaign<TMobileGeoFenceRetargetingCampaign> {}

export class FacebookCampaign<
  T extends TFacebookCampaign = TFacebookCampaign,
> extends CreativesCampaign<T> {
  public constraints: TConstraints<TFacebookCampaign> = {
    ...baseConstraints,
    facebook_page_id: {
      presence: {
        allowEmpty: false,
      },
    },
    publisher_platforms: {
      presence: {
        allowEmpty: false,
      },
    },
    objective_type: {
      presence: {
        allowEmpty: false,
      },
    },
    targeted_countries: {
      presence: {
        allowEmpty: false,
      },
    },
    monetization_value: () => {
      if (this.isMonetization) {
        return monetizationConstraints.monetization_value;
      }
      return null;
    },
  };

  @computed
  public get objectiveType(): string | undefined {
    return FacebookCampaignObjectiveMap.get(this.get('objective_type'));
  }
}
export class EmailListFacebookCampaign extends FacebookCampaign<TEmailListFacebookCampaign> {}

export abstract class EmailBaseCampaign<
  T extends IEmailBaseCampaign = IEmailBaseCampaign,
> extends Campaign<T> {
  public constraints: TConstraints<T> = {};

  constructor(attributes: Partial<T>) {
    super(attributes);

    makeObservable(this);
  }

  public getTemplatePreviewUrl(): string {
    return `${POLO_URL}view_email?cpn_id=${this.get('id')}&t_id=${this.get('template_id')}`;
  }
}

export class DripCampaign extends EmailBaseCampaign<IDripCampaign> {
  public constraints: TConstraints<IDripCampaign> = {};

  public getDefaults(): Partial<IDripCampaign> {
    return {
      ...super.getDefaults(),
      _cls: Drip,
    };
  }
}

export class GoogleAdsSmartCampaign extends CreativesCampaign<IGoogleAdsSmartCampaign> {
  public constraints: TConstraints<IGoogleAdsSmartCampaign> = {
    ...baseConstraints,
    destination_url: {
      url: {
        message: '^Website must be a valid URL.',
      },
      presence: {
        allowEmpty: false,
        message: "^Website can't be empty.",
      },
    },
    business_name: {
      presence: {
        allowEmpty: false,
        message: "^Business name can't be empty.",
      },
    },
    keyword_themes: {
      presence: {
        allowEmpty: false,
      },
    },
    'exposure_settings.target_cap_daily': {
      presence: {
        message: '^Daily average budget cannot be empty.',
        allowEmpty: false,
      },
      numericality: {
        greaterThan: 0,
        lessThanOrEqualTo: 329,
        message: '^Daily average budget must be greater than $0 and less than or equal to $329.',
      },
    },
    /*
     * Override the default date_start constraints from baseConstraints
     */
    date_start: {
      presence: {
        allowEmpty: false,
        message: '^Start date cannot be empty.',
      },
    },
    // Override the default date_end constraints from baseConstraints
    date_end: {
      datetime: (value: string, attributes: any) => {
        if (attributes.date_start) {
          const dateStart = moment.utc(attributes.date_start, TimeFormat.isoDateTime);
          const dateEnd = moment.utc(value, TimeFormat.isoDateTime);
          const today = moment.utc('', TimeFormat.isoDateTime);
          const endStartDiff = dateEnd.diff(dateStart);
          if (dateEnd.isBefore(today)) {
            return {
              earliest: today.format(TimeFormat.isoDateTime),
              message: '^End date must not be in the past.',
            };
          }
          /*
           * Campaigns can end on the same day as they start
           * Invalid end dates are before start date and not on start date
           */
          if (dateEnd.isBefore(dateStart) && endStartDiff !== 0) {
            return {
              earliest: dateStart.format(TimeFormat.isoDateTime),
              message: '^End date must be on or after the start date.',
            };
          }
        }
        return true;
      },
      /*
       * Campaigns can run indefinitely to use up that free Google money
       * allowEmpty: true was not working and falling back to some invisible validation
       */
      presence: undefined,
    },
  };

  public getDefaults(): Partial<IGoogleAdsSmartCampaign> {
    return {
      ...super.getDefaults(),
      _cls: GoogleAdsSmart,
    };
  }

  public async suggest<T extends TGoogleAdsSuggestions>({
    type = 'ad',
    businessName,
    destinationUrl,
    keywordThemes,
  }: IGoogleAdsSuggestProps): Promise<T> {
    this.assertCollection(this.collection, ECollectionClassName.GoogleAdsSmartCampaign);

    const variant = `google.suggest.${type}` as const;
    const url = this.collection.url(variant, this.id);

    const response = await wretch<T>(url, {
      method: 'POST',
      body: JSON.stringify({
        business_name: businessName,
        destination_url: destinationUrl,
        keyword_themes: keywordThemes,
      }),
      headers: this.collection.getHeaders(),
    });

    if (isWretchError(response)) {
      throw response.error;
    }

    return response.data;
  }

  public async getLocations(searchTerm: string): Promise<IGoogleAdsLocationSuggestion[]> {
    this.assertCollection(this.collection, 'GoogleAdsCustomer');

    const url = this.collection.url('google.location', searchTerm);

    const response = await wretch<IGoogleAdsLocationSuggestion[]>(url, {
      method: 'GET',
      headers: this.collection.getHeaders(),
    });

    if (isWretchError(response)) {
      throw response.error;
    }

    return response.data;
  }

  public get totalViews(): number {
    const { num_views: numViews = 0 } = this.get('total_stats') || {};
    return numViews;
  }

  public get totalClicks(): number {
    const { num_clicks: numClicks = 0 } = this.get('total_stats') || {};
    return numClicks;
  }
}

export class ReferralCampaign extends Campaign<TReferralCampaign> {
  public constraints: TConstraints<TReferralCampaign> = {
    ...baseConstraints,
    links: {
      array: {
        link_id: {
          presence: {
            allowEmpty: false,
          },
        },
        title: {
          presence: {
            allowEmpty: false,
          },
        },
        url: {
          presence: {
            allowEmpty: false,
          },
          url: true,
        },
      },
    },
    destination_url: (...args: any[]) => {
      const attributes = args[1];
      const url = args[0];
      if (attributes && url && url.startsWith('@')) {
        return {
          presence: { allowEmpty: false },
          format: {
            pattern: '^@.*@$',
            message: 'is not a valid merge pattern (must begin and end with "@").',
          },
        };
      }
      if (attributes && attributes.banner_templates && attributes.banner_templates.length) {
        return {
          url: true,
          presence: { allowEmpty: false },
        };
      } else {
        return undefined;
      }
    },
    banner_templates: {
      presence: {
        allowEmpty: true,
      },
    },
    page_templates: {
      presence: {
        allowEmpty: true,
      },
    },
  };

  public addTemplate(template: Template): void {
    if (!template.id) {
      throw new Error('Template is missing an id.');
    }

    const type = template.get('_cls');
    if (type === TemplateClass.ReferralBanner) {
      const bTemplateIds = this.get('banner_templates', observable([]));
      this.set({ banner_templates: [...bTemplateIds, template.id] } as Partial<Attributes<this>>);
    } else if (type === TemplateClass.ReferralEmail) {
      const eTemplateIds = this.get('email_templates', observable([]));
      this.set({ email_templates: [...eTemplateIds, template.id] } as Partial<Attributes<this>>);
    } else if (type === TemplateClass.ReferralPage) {
      const pTemplateIds = this.get('page_templates', observable([]));
      this.set({ page_templates: [...pTemplateIds, template.id] } as Partial<Attributes<this>>);
    }
    this.patchDirty();
  }

  public removeTemplate(templateId: string): void {
    const bTemplateIds: string[] = this.get('banner_templates', observable([]));
    if (bTemplateIds.includes(templateId)) {
      this.set({ banner_templates: bTemplateIds.filter((t) => t !== templateId) } as Partial<
        Attributes<this>
      >);
      this.save();
      return;
    }

    const eTemplateIds: string[] = this.get('email_templates', observable([]));
    if (eTemplateIds.includes(templateId)) {
      this.set({ email_templates: eTemplateIds.filter((t) => t !== templateId) } as Partial<
        Attributes<this>
      >);
      return;
    }

    const pTemplateIds: string[] = this.get('page_templates', observable([]));
    if (pTemplateIds.includes(templateId)) {
      this.set({ page_templates: pTemplateIds.filter((t) => t !== templateId) } as Partial<
        Attributes<this>
      >);
    }
  }
}

export class ConversationCampaign extends Campaign<TConversationCampaign> {
  public constraints: TConstraints<TConversationCampaign> = {
    // ...baseConstraints,
    url: {
      presence: {
        allowEmpty: false,
      },
      search: {
        pattern: /^[^*]*$/,
        message: 'must not contain *',
      },
    },
  };

  constructor(attributes: Partial<TConversationCampaign> = {}) {
    super(attributes);

    makeObservable(this);
  }

  public getDefaults(): Partial<TConversationCampaign> {
    return {
      ...super.getDefaults(),
      url: '',
    };
  }

  public get name(): string {
    const questions = this.get('questions');
    return this.get('name', '' as Exclude<TConversationCampaign['name'], undefined>).trim()
      ? this.get('name')
      : questions[0]?.text.trim()
        ? questions[0].text
        : 'Unnamed Conversation Campaign';
  }
}

export class LandingPageCampaign extends Campaign<TLandingPageCampaign> {}

export class TrackedLinkCampaign extends Campaign<TTrackedLinkCampaign> {
  public constraints: TConstraints<TCampaignAttributes> = {
    ...baseConstraints,
    date_start: {
      // Tracked link campaigns do not depend on any temporal data.
      presence: {
        allowEmpty: true,
      },
    },
    tracked_links: {
      length: {
        minimum: 1,
        message: '^Please add a tracked link.',
      },
      array: {
        original_url: {
          presence: {
            allowEmpty: false,
            message: '^A valid URL must be provided.',
          },
          url: {
            message: '^A valid URL must be provided.',
          },
        },
        utm_source: {
          presence: {
            allowEmpty: false,
            message: '^Please add UTM source.',
          },
        },
        utm_medium: {
          presence: {
            allowEmpty: false,
            message: '^Please add UTM medium.',
          },
        },
      },
    },
  };

  public getDefaults(): Partial<TTrackedLinkCampaign> {
    return {
      ...super.getDefaults(),
      date_end: moment.utc().add(1, 'y').format(TimeFormat.isoDate),
    };
  }
}

// Use presenceUnless instead of presence.
const pinpointEmailCampaignAddressConstraints = Object.keys(addressConstraints).reduce(
  (previousValue, currentValue) => {
    const constraint = addressConstraints[currentValue as keyof IAddress];
    if (constraint) {
      previousValue[`address.${currentValue}`] = constraint;
    }
    return previousValue;
  },
  {} as TConstraints<IPinpointEmailCampaign>,
);

const pinpointEmailBaseCampaignConstraints: TConstraints<IPinpointEmailBaseCampaign> = {
  date_send_start: {
    datetime: (_: string, attributes: Partial<IPinpointEmailBaseCampaign>) => {
      if (attributes.send_schedule === 'now') {
        return undefined;
      }
      if (
        attributes.state &&
        [CampaignState.Draft, CampaignState.Stopped].includes(attributes.state)
      ) {
        return {
          earliest: moment.utc().add(15, 'minutes'),
          message: `^Start time must be at least 15 minutes in the future.`,
        };
      }
      return undefined;
    },
    presence: (_: string, attributes: Partial<IPinpointEmailBaseCampaign>) => {
      if (attributes.send_schedule === 'now') {
        return undefined;
      }
      return {
        allowEmpty: false,
        message: '^Start time cannot be empty.',
      };
    },
  },
  'consent.has_consent': {
    presence: {
      allowEmpty: false,
      message: '^You must provide consent to launch this campaign.',
    },
    exclusion: {
      within: [false],
      message: '^You must provide consent to launch this campaign.',
    },
  },
  'consent.user': {
    presence: {
      allowEmpty: false,
      message: '^Only a valid user may provide consent.',
    },
  },
  'consent.date_consented': {
    datetime: true,
    presence: {
      allowEmpty: false,
      message: '^Must provide date and time consent was given.',
    },
  },
  from_address: {
    presence: {
      allowEmpty: false,
      message: '^An email address must be provided.',
    },
    email: {
      message: '^From email address is not valid.',
    },
  },
  from_name: {
    presence: {
      allowEmpty: false,
      message: "^From Name can't be blank.",
    },
    format: {
      pattern: "[a-z0-9!#$%&*()\\-+=._?/ '‘]+",
      flags: 'i',
      message:
        "^From Name can only contain alphanumeric characters, and the following symbols: ! # $ % & * ( ) - + = . _ ? / ' . ‘",
    },
  },
  name: {
    exclusion: {
      within: ['Unnamed Single Send Campaign'],
      message: '^Change the default name for this campaign.',
    },
    presence: {
      allowEmpty: false,
    },
  },
  segments: {
    presence: {
      allowEmpty: false,
      message: '^Please add at least one group.',
    },
    array: {
      id: {
        presence: {
          allowEmpty: false,
          message: '^Please select an audience for the group.',
        },
      },
    },
    inclusion: (...args: any[]) => {
      const { segments } = args[1];

      if (segments.some(({ included }) => included)) {
        return null;
      }

      return { message: '^Please add at least one included group.' };
    },
  },
  template_id: {
    presence: { allowEmpty: false, message: '^Please choose a template.' },
  },
  subject: {
    presence: { allowEmpty: false, message: '^The email must have a subject.' },
  },
  ...pinpointEmailCampaignAddressConstraints,
};

const smartPinpointEmailBaseCampaignConstraints: TConstraints<ISmartPinpointEmailBaseCampaign> = {
  date_send_end: {
    datetime: (value: string, attributes: Partial<ISmartPinpointEmailBaseCampaign>) => {
      if (attributes.date_send_start) {
        const dateStart = moment.utc(attributes.date_send_start, moment.ISO_8601);
        return {
          earliest: dateStart.add(23, 'hours').add(59, 'minutes').format(TimeFormat.isoDateTime),
          message: '^End time must be at least 24 hours after start time.',
        };
      }
      return undefined;
    },
    presence: {
      allowEmpty: false,
      message: '^End time cannot be empty.',
    },
  },
  name: {
    exclusion: {
      within: ['Unnamed Smart Delivery Campaign'],
      message: '^Change the default name for this campaign.',
    },
    presence: {
      allowEmpty: false,
    },
  },
  delay_value: {
    presence: {
      allowEmpty: false,
    },
  },
  delay_unit: {
    presence: {
      allowEmpty: false,
    },
  },
  actions(...args: any[]) {
    const { actions } = args[1];
    if (actions.length < 1) {
      return { length: { minimum: 1, message: '^You must have at least one trigger activity.' } };
    }

    // "Exists" and "not exists" do not need to have a value.
    const filteredActions = actions.filter(
      ({ comparison }) => !['exists', 'nexists'].includes(comparison),
    );

    // True and false are valid values, so we only want to check for undefined, empty string, and null.
    if (filteredActions.some(({ value }) => ['', undefined, null].includes(value))) {
      return {
        array: {
          value: { presence: { allowEmpty: false, message: '^Trigger activity cannot be blank.' } },
        },
      };
    }

    return null;
  },
  ...pinpointEmailBaseCampaignConstraints,
};
export abstract class PinpointEmailBaseCampaign<
  T extends IPinpointEmailBaseCampaign = IPinpointEmailBaseCampaign,
> extends EmailBaseCampaign<T> {
  public constraints: TConstraints<IPinpointEmailBaseCampaign> =
    pinpointEmailBaseCampaignConstraints;

  constructor(attributes: Partial<T>) {
    super(attributes);

    makeObservable(this);
  }

  public getDefaults(): Partial<T> {
    return {
      ...super.getDefaults(),
      segments: [],
    } as Partial<T>;
  }

  @computed
  public get isPastStartDate(): boolean {
    const sendTime = moment.utc(this.get('date_send_start'), moment.ISO_8601);
    const now = moment.utc();
    return now.isSameOrAfter(sendTime);
  }

  @computed
  public get editable(): boolean {
    const state = this.get('state');

    /*
     * Because of limitations with mobx and subclassing, we cannot override editable
     * in SmartPinpointEmailBaseCampaign.
     */
    if (
      (this.attributes._cls === CampaignClass.AutoPinpointEmail ||
        this.attributes._cls === CampaignClass.SmartPinpointEmail) &&
      state === CampaignState.Stopped
    ) {
      // Prevent editing or re-running smart and auto pinpoint campaigns that are stopped.
      return false;
    }
    return true;
  }

  @computed
  public get readOnly(): boolean {
    const state = this.get('state');
    if (
      [CampaignState.Published, CampaignState.Publishing, CampaignState.Archived].includes(state)
    ) {
      return true;
    }
    return this.isPastStartDate && state !== CampaignState.Draft;
  }

  public get totalViews(): number {
    const stats = this.get('total_stats') || {};
    const flavors = stats.flavors || {};
    return flavors.pinpoint_tracked_email_open || 0;
  }

  public get totalClicks(): number {
    const stats = this.get('total_stats') || {};
    const flavors = stats.flavors || {};
    return flavors.pinpoint_tracked_email_click || 0;
  }

  public async sendTestEmail(request: { email: string[]; per_id: string }): Promise<T> {
    this.assertCollection(this.collection, ECollectionClassName.PinpointEmailBaseCampaign);
    this.assertId(this.id, ECollectionClassName.PinpointEmailBaseCampaign);

    const url = `${BLACKBOX_URL}flight_campaigns/${this.id}/test`;
    const headers = this.collection.getHeaders();
    const response = await wretch<T>(url, {
      headers,
      body: JSON.stringify(request),
      method: 'POST',
    });
    if (isWretchError(response)) {
      runInAction(() => {
        this.isUpdating = false;
        this.isErrored = true;
        this.error = response.error;
      });
      throw response.error;
    } else {
      runInAction(() => {
        this.isUpdating = false;
        this.isErrored = false;
        this.error = null;
      });
    }
    /*
     * TODO: Figure out best way to mimic or call this.collection.processJSONResponse(response)
     * Perhaps move contents of function to collection, and have this be a simple wrapper to it?
     */
    return response.data;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public removeTemplate(templateId: string): void {
    // TODO: filter out by template id when multiple templates can be used

    /*
     * Due to multiple levels of generic inheritance, typescript does not
     * properly recognize template_id as valid, so use any.
     */
    this.set({ template_id: undefined } as any);
    this.save();
  }

  public addTemplate(template: Template): void {
    if (!template.id) {
      throw new Error('Template is missing an id.');
    }

    const type = template.get('_cls');

    if (type !== TemplateClass.PinpointEmail) {
      throw new Error('Template is not an Email Template.');
    }

    /*
     * Due to multiple levels of generic inheritance, typescript does not
     * properly recognize subject or template_id as valid, so use any.
     */
    const campaignSubject = this.get('subject');
    const templateSubject = template.get('subject');
    if (!campaignSubject && !!templateSubject) {
      this.set({ subject: templateSubject } as any);
    } else {
      template.set({ subject: campaignSubject } as any);
    }
    this.set({ template_id: template.id } as any);
    template.patchDirty();
    this.patchDirty();
  }

  public getEmailVerifications(
    EmailVerificationsCollection: EmailVerifications,
  ): ListResponse<EmailVerification> {
    return EmailVerificationsCollection.list({ filters: { email: this.get('from_address') } });
  }

  public getEmailVerification(
    emailVerifications: ListResponse<EmailVerification>,
  ): EmailVerification | undefined {
    return emailVerifications &&
      !emailVerifications.isErrored &&
      emailVerifications.models.length > 0
      ? emailVerifications.models[0]
      : undefined;
  }
}

export class PinpointEmailCampaign extends PinpointEmailBaseCampaign<IPinpointEmailCampaign> {
  /**
   * Single Send campaigns using an email address that does not belong to a verified Email Domain
   * will be limited to <= 1000 sends
   */
  public constraints: TConstraints<IPinpointEmailCampaign> = {
    ...pinpointEmailBaseCampaignConstraints,
    monetization_value: () => {
      if (this.isMonetization) {
        return monetizationConstraints.monetization_value;
      }
      return null;
    },
    send_schedule: {
      presence: {
        allowEmpty: false,
        message: '^Please choose a send time.',
      },
    },
  };
}

export abstract class SmartPinpointEmailBaseCampaign<
  T extends ISmartPinpointEmailBaseCampaign = ISmartPinpointEmailBaseCampaign,
> extends PinpointEmailBaseCampaign<T> {
  public constraints: TConstraints<T> = smartPinpointEmailBaseCampaignConstraints;

  public getDefaults(): Partial<T> {
    return {
      ...super.getDefaults(),
      mode: 'match_all',
      actions: [
        /**
         * Defining the default actions like this allows us
         * to render the first PredicateInput in an unselected state.
         * This is necessary to show the user that both activity and
         * attribute predicates are available for selection.
         */
        {
          attr_against: undefined,
        },
      ],
    };
  }
}

export class SmartPinpointEmailCampaign extends SmartPinpointEmailBaseCampaign<ISmartPinpointEmailCampaign> {
  /**
   * Smart Send campaigns using an email address that does not belong to a verified Email Domain
   * will be limited to <= 1000 sends
   */
  public constraints: TConstraints<ISmartPinpointEmailCampaign> = {
    ...smartPinpointEmailBaseCampaignConstraints,
    send_all: {
      presence: {
        allowEmpty: false,
      },
    },
  };
}

export class AutoPinpointEmailCampaign extends SmartPinpointEmailBaseCampaign<IAutoPinpointEmailCampaign> {
  public constraints: TConstraints<IAutoPinpointEmailCampaign> = {
    ...smartPinpointEmailBaseCampaignConstraints,
    repeat: {
      presence: {
        allowEmpty: false,
        message:
          "^Please choose whether you'd like your email to be sent only once or each time the action is performed.",
      },
    },
    segments: {
      presence: {
        allowEmpty: true,
      },
    },
    cooldown_value: {
      presence: {
        allowEmpty: false,
      },
    },
    cooldown_unit: {
      presence: {
        allowEmpty: false,
      },
    },
    // Only validate the presence of time to send if a date trigger type has been selected
    time_to_send: {
      presenceUnless: {
        allowEmpty: false,
        message: "^Time of day can't be blank",
        unless: ({ subtype }) => subtype !== 'time',
      },
    },
  };

  public getDefaults(): Partial<IAutoPinpointEmailCampaign> {
    return {
      ...super.getDefaults(),
      mode: 'match_all',
      actions: [
        /**
         * Defining the default actions like this allows us
         * to render the first PredicateInput in an unselected state.
         * This is necessary to show the user that both activity and
         * attribute predicates are available for selection.
         */
        {
          attr_against: undefined,
          kind: 'update',
        },
      ],
      send_schedule: 'later',
      delay_value: 30,
      delay_unit: 'minutes',
    };
  }
}

export class PinpointPartnerMessage extends PinpointEmailBaseCampaign<IPinpointPartnerMessage> {
  public constraints: TConstraints<IPinpointPartnerMessage> = {
    date_send_start: {
      datetime: (_: string, attributes: Partial<IPinpointPartnerMessage>) => {
        if (attributes.send_schedule === 'now') {
          return undefined;
        }
        if (
          attributes.state &&
          [CampaignState.Draft, CampaignState.Stopped].includes(attributes.state)
        ) {
          return {
            earliest: moment.utc().add(15, 'minutes'),
            message: `^Start time must be at least 15 minutes in the future.`,
          };
        }
        return undefined;
      },
      presence: (_: string, attributes: Partial<IPinpointPartnerMessage>) => {
        if (attributes.send_schedule === 'now') {
          return undefined;
        }
        return {
          allowEmpty: false,
          message: '^Start time cannot be empty.',
        };
      },
    },
    name: {
      presence: {
        allowEmpty: false,
      },
    },
    from_address: {
      presence: {
        allowEmpty: false,
      },
      email: (...args: any[]) => {
        const attributes = args[1];
        const isEmpty = !attributes.from_address;
        if (isEmpty) {
          return undefined;
        }
        return {
          message: '^From address must be a valid email address.',
        };
      },
    },
    from_name: {
      presence: {
        allowEmpty: false,
        message: "^From Name can't be blank.",
      },
      format: {
        pattern: "[a-z0-9!#$%&*()\\-+=._?/ '‘]+",
        flags: 'i',
        message:
          "^From Name can only contain alphanumeric characters, and the following symbols: ! # $ % & * ( ) - + = . _ ? / ' . ‘",
      },
    },
    'consent.has_consent': {
      presence: {
        allowEmpty: false,
        message: '^You must provide consent to send this message.',
      },
      exclusion: {
        within: [false],
        message: '^You must provide consent to send this message.',
      },
    },
    'consent.user': {
      presence: {
        allowEmpty: false,
        message: '^Only a valid user may provide consent.',
      },
    },
    'consent.date_consented': {
      datetime: true,
      presence: {
        allowEmpty: false,
        message: '^Must provide date and time consent was given.',
      },
    },
    'participation.mode': {
      presence: {
        allowEmpty: false,
        message: '^Please choose a partner selection mode.',
      },
    },
    'participation.partner_ids': (_: string, attributes: Partial<IPinpointPartnerMessage>) => {
      if (attributes.participation?.['mode'] === 'manual') {
        return {
          presence: {
            allowEmpty: false,
            message: '^You must specify at least one recipient if you choose "select partners".',
          },
        };
      }
      return undefined;
    },
    template_id: {
      presence: {
        allowEmpty: false,
      },
    },
    subject: {
      presence: { allowEmpty: false, message: '^Your partner message must have a subject.' },
    },
  };

  public getItemUrl(pathSuffix?: string): string {
    return concatPath(`/projects/${this.get('event')}/partners/messages/${this.id}`, pathSuffix);
  }

  public getItemLabel(): string {
    if (this.get('name')) {
      return this.get('name');
    }
    const sendAt = this.get('date_send_start');
    return `Unnamed message${
      !!sendAt && ` (${moment.utc(sendAt).format(TimeFormat.shortDateTime)})`
    }`;
  }

  public get name(): string {
    return this.get('name', '').trim() || `Unnamed Message`;
  }

  public get isEditable(): boolean {
    return !(
      this.isPastStartDate &&
      [CampaignState.Published, CampaignState.Publishing].includes(
        this.get('state', CampaignState.Draft),
      )
    );
  }
}

export function disambiguateCampaign(attributes: any) {
  switch (attributes._cls) {
    case Segment:
      return new SegmentCampaign(attributes);

    case Lookalike:
      return new LookalikeCampaign(attributes);

    case SeedSegment:
      return new SeedSegmentCampaign(attributes);

    case Affinity:
      return new AffinityCampaign(attributes);

    case Search:
      return new SearchCampaign(attributes);

    case EmailList:
      return new EmailListCampaign(attributes);

    case Referral:
      return new ReferralCampaign(attributes);

    case LandingPage:
      return new LandingPageCampaign(attributes);

    case Conversation:
      return new ConversationCampaign(attributes);

    case MobileGeoFencing:
      return new MobileGeoFencingCampaign(attributes);

    case MobileGeoFenceRetargeting:
      return new MobileGeoFenceRetargetingCampaign(attributes);

    case Facebook:
      return new FacebookCampaign(attributes);

    case GoogleAdsSmart:
      return new GoogleAdsSmartCampaign(attributes);

    case EmailListFacebook:
      return new EmailListFacebookCampaign(attributes);

    case TrackedLink:
      return new TrackedLinkCampaign(attributes);

    case PinpointEmail:
      return new PinpointEmailCampaign(attributes);

    case SmartPinpointEmail:
      return new SmartPinpointEmailCampaign(attributes);

    case AutoPinpointEmail:
      return new AutoPinpointEmailCampaign(attributes);

    case PartnerMessage:
      return new PinpointPartnerMessage(attributes);

    case Drip:
      return new DripCampaign(attributes);

    default:
      return new BaseCampaign(attributes);
  }
}

// Function that allows type narrowing for Landing Page Campaigns.
export function isLandingPageCampaign(campaign: Campaign): campaign is LandingPageCampaign {
  return campaign.get('_cls') === LandingPage;
}

export function isDripCampaign(campaign: Campaign): campaign is DripCampaign {
  return campaign.get('_cls') === Drip;
}

// Function that allows type narrowing for Pinpoint Email Campaigns.
export function isEmailCampaign(campaign: Campaign): campaign is PinpointEmailBaseCampaign {
  return campaign.isEmail;
}

export type TCampaigns =
  | BaseCampaign
  | SegmentCampaign
  | LookalikeCampaign
  | SeedSegmentCampaign
  | AffinityCampaign
  | SearchCampaign
  | EmailListCampaign
  | ReferralCampaign
  | LandingPageCampaign
  | ConversationCampaign
  | MobileGeoFencingCampaign
  | MobileGeoFenceRetargetingCampaign
  | FacebookCampaign
  | EmailListFacebookCampaign
  | TrackedLinkCampaign
  | PinpointEmailCampaign
  | SmartPinpointEmailCampaign
  | AutoPinpointEmailCampaign;

export type TCampaignAttributes =
  | IAutoPinpointEmailCampaign
  | ICampaignAttributes
  | IGoogleAdsSmartCampaign
  | IPinpointEmailCampaign
  | ISegmentCampaignAttributes
  | ISmartPinpointEmailCampaign
  | TConversationCampaign
  | TEmailListCampaign
  | TFacebookCampaign
  | TLandingPageCampaign
  | TLookalikeCampaign
  | TSeedSegmentCampaign
  | TAffinityCampaign
  | TMobileGeoFenceRetargetingCampaign
  | TMobileGeoFencingCampaign
  | TReferralCampaign
  | TSearchCampaign
  | TTrackedLinkCampaign;

export interface ICampaignPartnerStats extends Record<string, unknown> {
  error: string;
  p_id: string;
  views: number;
  clicks: number;
  conversions: number;
  leads: number;
}

export interface ICampaignPartnerStatsMetadata extends IMetadata {
  is_partial: boolean;
}

type TCollectionUrlVariant =
  | 'export'
  | 'google.suggest.budget'
  | 'google.suggest.keyword'
  | 'google.suggest.ad'
  | 'google.location';

export interface IExportCampaignsProps {
  attribution_model?: TAttributionModel;
  columns: string[];
  email: string;
  filename?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filters?: Record<string, any>;
  sort?: string[];
}

export class Campaigns<
  Model extends Campaign<ICampaignAttributes> = Campaign<ICampaignAttributes>,
> extends BulkCollection<Model> {
  constructor(initialModels: Array<Partial<Attributes<Model>>>, store: ICollectionStore = {}) {
    super(initialModels, store);

    makeObservable(this);
  }

  public getModel(attributes: Partial<Attributes<Model>>): Model {
    return disambiguateCampaign(attributes) as Model;
  }

  public url(variant?: TCollectionUrlVariant, value?: string): string {
    const googlePath = `${this.getHostname()}integrations/google-ads`;
    const suggestPath = `${googlePath}/campaign/${value}/suggest`;

    switch (variant) {
      case 'export':
        return `${this.getHostname()}exports/campaigns`;

      case 'google.suggest.budget':
        return `${suggestPath}/budget`;

      case 'google.suggest.keyword':
        return `${suggestPath}/keyword_theme`;

      case 'google.suggest.ad':
        return `${suggestPath}/ad`;

      case 'google.location':
        return `${googlePath}/location?term=${value}`;

      default:
        return `${this.getHostname()}flight_campaigns/`;
    }
  }

  public getClassName(): string {
    return 'campaigns';
  }

  @action
  public async publish(model: Model): Promise<Model> {
    model.isUpdating = true;
    const response = await wretch<ICampaignAttributes>(`${this.url()}${model.id}/publish`, {
      method: 'POST',
      headers: this.getHeaders(),
    });
    if (isWretchError(response)) {
      runInAction(() => {
        model.isUpdating = false;
        model.isErrored = true;
        model.error = response.error;
      });
      return model;
    }
    return this.processJSONResponse(response);
  }

  public async getPartnerStats(
    id: string,
    start: string,
    end: string,
    attributionModel: TAttributionModel,
    sortParams: Array<{
      id: string;
      desc?: true;
    }>,
    paginationParams: { page: number; page_size: number },
  ): Promise<IWretchResponseValid<ICampaignPartnerStats[], ICampaignPartnerStatsMetadata>> {
    const params = {
      start,
      end,
      attribution_model: attributionModel,
      pagination: paginationParams,
      ordering: sortParams.map((s) => (s.desc ? `-${s.id}` : s.id)),
    };
    const search = toQueryString(this.parseJSONParams(params));
    const response = await wretch<ICampaignPartnerStats[], ICampaignPartnerStatsMetadata>(
      `${this.getHostname()}campaigns/${id}/partner_stats?${search}`,
      {
        method: 'GET',
        headers: this.getHeaders(),
      },
    );
    if (isWretchError(response)) {
      throw response.error;
    }
    return response;
  }

  public async exportPartnerStats(
    id: string,
    start: string,
    end: string,
    attributionModel: TAttributionModel,
    sortParams: Array<{
      id: string;
      desc?: true;
    }>,
    paginationParams: { page: number; page_size: number },
    email: string,
  ): Promise<IRachisMessage> {
    const params = {
      partner_stats: '1',
      start,
      end,
      email,
      attribution_model: attributionModel,
      pagination: paginationParams,
      ordering: sortParams.map((s) => (s.desc ? `-${s.id}` : s.id)),
    };
    const search = toQueryString(this.parseJSONParams(params));
    const response = await wretch<IRachisMessage>(
      `${this.getHostname()}campaigns/${id}/export?${search}`,
      {
        method: 'GET',
        headers: this.getHeaders(),
      },
    );

    if (isWretchError(response)) {
      throw response.error;
    }
    return response.data;
  }

  public async exportCampaigns({
    // eslint-disable-next-line @typescript-eslint/naming-convention
    attribution_model,
    columns,
    email,
    filename,
    filters,
    sort,
  }: IExportCampaignsProps): Promise<IRachisMessage> {
    const response = await wretch<IRachisMessage>(this.url('export'), {
      method: 'POST',
      headers: this.getHeaders(),
      body: JSON.stringify({ attribution_model, columns, email, filename, filters, sort }),
    });

    if (isWretchError(response)) {
      throw response.error;
    }
    return response.data;
  }

  public async addCampaign(
    type: CampaignClass,
    eventId: string,
    overrides: Record<string, any> = {},
  ): Promise<Model> {
    // TODO: Why isn't this in getDefaults()?
    const campaignAttrs: Record<string, any> = {
      _cls: type,
      event: eventId,
      parent: eventId,
      parent_kind: 'event',
      state: 'draft',
      name: '',
      exposure_settings: {
        target_value: 0,
      },
    };

    if (
      [
        Segment,
        Search,
        Lookalike,
        SeedSegment,
        Affinity,
        EmailList,
        MobileGeoFencing,
        MobileGeoFenceRetargeting,
        Facebook,
        EmailListFacebook,
      ].includes(type)
    ) {
      campaignAttrs.exposure_settings = {
        base_bid: 5,
        max_bid: 10,
        custom_bid: false,
        custom_target: false,
        target_value: CAMPAIGN_MIN_BUDGET,
      };
      campaignAttrs.exposure_model = {
        freq_cap: 9,
        freq_period: 480,
        intensity: 'medium',
      };
    } else if (type === LandingPage) {
      (campaignAttrs as TLandingPageCampaign).template = {
        ...defaultPageTemplate,
        _cls: TemplateClass.Page,
        short_code: shortCodeToken(12),
      } as unknown as ITemplate;
    } else if (type === TrackedLink) {
      campaignAttrs.domain_id = '000000000000000000000000';
    }
    const campaign = this.create({ ...campaignAttrs, ...overrides } as Partial<Attributes<Model>>);
    return this.add(campaign, { validate: false });
  }
}
