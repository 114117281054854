import { faArrowLeft, faArrowRight, faCheck } from '@fortawesome/pro-regular-svg-icons';
import { when } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import type { Campaign } from '@feathr/blackbox';
import { DripCampaign } from '@feathr/blackbox';
import type { IWizardStepsCompleted } from '@feathr/components';
import {
  AlertV2 as Alert,
  Button,
  ButtonValid,
  ConfirmModalV1,
  EAlertV2Type,
  Icon,
  Step,
  Steps,
  useWizardState,
  Wizard,
} from '@feathr/components';
import { StoresContext, useAccount, useActionBar } from '@feathr/extender/state';
import { useDeepCompareEffect } from '@feathr/hooks';
import { useToggle } from '@feathr/hooks';
import type { Model } from '@feathr/rachis';

import SaveCampaignButton, { save } from '../SaveCampaignButton';
import { getGoalSegments } from '../StepGoals';
import BuilderStep from './BuilderStep';
import DetailsStep from './DetailsStep';
import ExclusionsStep from './ExclusionsStep';
import GoalsStep from './GoalsStep';

import * as styles from './DripCampaignEdit.css';

interface IDripCampaignEditProps {
  campaign: DripCampaign;
}

function DripCampaignEdit({ campaign }: Readonly<IDripCampaignEditProps>): JSX.Element {
  const [isPublishModalOpen, togglePublishModalOpen] = useToggle(false);
  const { setLeftActions, setRightActions } = useActionBar();
  const { t } = useTranslation();
  const account = useAccount();
  const { Goals, Segments } = useContext(StoresContext);

  const getStepErrors = useCallback(
    (step: number): string[] => {
      // If we're loading the wizard, return no errors
      if (step === -1) {
        return [];
      }
      const map = {
        0: ['error to force it to be on first step'],
        1: [],
        2: [],
        3: [],
      };
      return map[step];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const getCompletedStepMap = (): IWizardStepsCompleted => {
    return {
      0: !getStepErrors(0).length,
      1: !getStepErrors(1).length,
      2: !getStepErrors(2).length,
      3: !getStepErrors(3).length,
    };
  };

  const goals = Goals.list({
    filters: {
      _parent: campaign.id,
      is_archived__ne: true,
    },
  });
  const goalSegments = getGoalSegments(goals.models, Segments);

  const segments = campaign
    .get('segments', [])
    .filter(({ id }) => !!id)
    .map(({ id }) => Segments.get(id));

  const childModels: Model[] = [...segments, ...goals.models.slice()];
  const grandchildModels: Model[] = [...goalSegments];

  // Allows the wizard to know when it is ready to move to the next step
  const waitFor: () => boolean = () => {
    // Should wait for necessary models to be loaded - just a shell for now
    return !(campaign.isPending || goals.isPending);
  };

  async function preSave(newCampaign: Campaign): Promise<void> {
    if (newCampaign instanceof DripCampaign) {
      const campaignSegments = newCampaign.get('segments');
      await Promise.all(
        campaignSegments.map(async (s) => {
          if (!s.id) {
            return;
          }
          const campaignSegment = Segments.get(s.id);
          await when(() => !campaignSegment.isPending);

          if (campaignSegment.isEphemeral) {
            await Segments.add(campaignSegment);
          }
        }),
      );
    }
  }

  const { currentStep, lastStep, onNext, onPrev, onChangeStep } = useWizardState({
    getCompletedStepMap,
    waitFor,
  });

  const steps = (
    <Steps completed={0} current={currentStep} onChange={onChangeStep}>
      <Step key={1} stepIndex={0} title={t('Builder')} />
      <Step key={2} stepIndex={1} title={t('Exclusions')} />
      <Step key={3} stepIndex={2} title={t('Goals')} />
      <Step key={4} stepIndex={3} title={t('Details')} />
    </Steps>
  );

  function handlePublish(): Promise<void> {
    togglePublishModalOpen();

    return save({
      campaign,
      childModels,
      grandchildModels: [],
      shouldChangeState: true,
      t,
      accountId: account.id,
    });
  }

  useDeepCompareEffect(() => {
    setLeftActions(
      <>
        <Button disabled={currentStep === 0} onClick={onPrev} prefix={<Icon icon={faArrowLeft} />}>
          {t('Previous')}
        </Button>
        <Button
          disabled={currentStep === lastStep}
          name={'next_step'}
          onClick={onNext}
          suffix={<Icon icon={faArrowRight} />}
        >
          {t('Next')}
        </Button>
      </>,
    );
    setRightActions(
      <>
        <SaveCampaignButton
          campaign={campaign}
          childModels={childModels}
          grandchildModels={grandchildModels}
          key={'save'}
          name={'save_changes'}
          preSave={preSave}
          showIcon={true}
        />
        {/* TODO: Enable button when campaign is valid 3714 */}
        <ButtonValid
          disabled={currentStep !== lastStep}
          // TODO: Add validation 3714
          errors={[]}
          name={'publish'}
          onClick={togglePublishModalOpen}
          prefix={<Icon icon={faCheck} />}
          tooltip={currentStep !== lastStep ? t('Complete all steps to publish') : undefined}
          tooltipPosition={'top-end'}
          type={'success'}
        >
          {t('Publish')}
        </ButtonValid>
      </>,
    );
  }, [currentStep, goals.models, goalSegments, segments]);

  return (
    <Wizard className={styles.wizard} isFullWidth={true} layout={'horizontal'} steps={steps}>
      <section className={styles.main}>
        {currentStep === 0 && <BuilderStep />}
        {currentStep === 1 && <ExclusionsStep campaign={campaign} />}
        {currentStep === 2 && <GoalsStep campaign={campaign} goals={goals.models} />}
        {currentStep === 3 && <DetailsStep campaign={campaign} />}
      </section>
      {isPublishModalOpen && (
        <ConfirmModalV1
          cancelButtonText={t('Cancel')}
          confirmButtonText={t('Publish')}
          confirmButtonType={'success'}
          onClose={togglePublishModalOpen}
          onConfirm={handlePublish}
          t={t}
          title={t('Publish campaign')}
        >
          <Alert
            description={t(
              'After publishing this drip campaign, you won’t be able to edit anything other than its goals.',
            )}
            title={t('Heads up!')}
            type={EAlertV2Type.warning}
          />
        </ConfirmModalV1>
      )}
    </Wizard>
  );
}

export default observer(DripCampaignEdit);
